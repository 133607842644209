.btn-information {
    button {
      color: #000;
      background-color: #e1e6ec;
      border-color: #e1e6ec;

      &:hover {
        color: #000 !important;
        background-color: darken(#e1e6ec, 10%);
        border-color: darken(#e1e6ec, 10%);
      }
    }
  }

  .list-group-item button i {
	padding-right: 0 !important;
  }