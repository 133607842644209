.drawer.right.drawer-small.person-drawer {
	min-width: min(500px, 90%);
	width: 40%;
}

.basic-layout .nav-tabs {
	padding-left: 2rem;
}

.tripForm {
	.container-sm {
		max-width: 100% !important;
		padding: 0;
	}
	.content-area {
		border-radius: 0;
	}
}

.fullWidth {
	.container-sm {
		max-width: 100% !important;
		padding: 0;
	}
	.content-area {
		border-radius: 0;
	}
}

.high-risk-trip-form {
	height: 81vh;
	overflow-x: hidden;
	overflow-y: scroll;
	form {
		padding: 1rem;
	}
}

.basic-layout .content-area {
	height: calc(100% - 7rem) !important;
	min-height: calc(100vh - 7rem) !important;
}

.high-risk-travellers-drawer .drawer-header .close {
	position: absolute;
	top: 35px;
	right: 35px;
}

.high-risk-travellers-drawer .drawer-body {
	padding: 0 !important;
}

#contact .container {
	padding: 0;
}

.requirements hr {
	margin: 0;
}

.devider-line {
	position: relative;
	&:after {
		content: '';
		width: 92%;
		height: 1px;
		background-color: rgba(0, 0, 0, 0.1);
		position: absolute;
		bottom: -3px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.confirm-area {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid #dee2e6;
	border-radius: 0.75rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin: 0;
}

.uploaded-files {
	display: flex;
	width: 100%;
	border: 1px solid #dee2e6;
	border-radius: 0.75rem;
	align-items: center;
	flex-direction: column;
	padding: 2rem 0;
}

.uploaded-file {
	width: 100%;
}

.progress-dot {
	width: 0.9rem;
	height: 0.9rem;
	border-radius: 50%;
	margin: 0 auto;

	&.green {
		background-color: #029600;
	}

	&.red {
		// border: 1px solid #E3124B;
		background-color: #e3124b;
	}

	&.blue {
		background-color: #427bd4;
	}

	&.blue-outlined {
		border: 1px solid #427bd4;
		// background-color: #427BD4;
	}

	&.grey {
		background-color: #6c757d;
	}

	&.grey-outlined {
		border: 1px solid #6c757d;
		// background-color: #427BD4;
	}
}

@media only screen and (max-width: 992px) {
	.desktop-navigation {
		display: none;
	}
	.master-detail {
		border-radius: 0rem 0rem 0rem 0.9rem;

		.detail {
			//This implies that Master is infront
			left: 100%;
			position: absolute;
			z-index: 21;
			transition: all 0.2s ease-in-out;
			//
			.header > h4 {
				height: 5.5rem;
				width: 85%;
			}
			.mobile-navigation {
				position: absolute;
				display: inline-block;

				height: 6.5rem;
				cursor: pointer;
				a {
					position: absolute;
					display: inline-block;
					padding: 0rem;
					height: 4.5rem;
					cursor: pointer;
					width: 3rem;
					text-align: center;
					i {
						font-size: 0.9rem * 1.5;
						width: 3rem;
						line-height: 4.25em;
					}
				}
			}
		}
		.detail.infront {
			left: 0%;
			transition: all 0.2s ease-in-out;
		}
	}
}
