body {
	margin: 0px;
}
.column-layout-top {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 3em 0;
}

.column-left {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex: 1.5 1;
	justify-content: flex-end;

	.progress-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 0 !important;
	}
}

.column-left > *:last-child {
	margin-top: 1em;
}

.column-layout-top > .column-center {
	display: flex;
	flex: 4;
	padding-right: 7em;
}

.column-layout-top > .column-right {
	display: flex;
	flex: 2;
	padding: 0 2em;
	justify-content: flex-start;
	& p {
		margin: 0;
	}
	& p:nth-child(2n) {
		font-weight: bold;
		margin-bottom: 1em;
	}
}

.table-layout {
	& .table-header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-top: 1px solid #6c757d77;
		border-bottom: 1px solid #6c757d77;
		& > * {
			padding: 1em 0;
			flex: 3;
			flex-basis: 0;
			white-space: nowrap;
			text-align: center;
		}
		& > span:first-of-type {
			flex: 1;
			flex-basis: 0;
		}
	}

	& > .table-body {
		& > .table-row {
			display: flex;
			width: 100%;
			flex: 1;
			align-items: center;
			justify-content: flex-start;
			border-bottom: 1px solid #6c757d77;
			& > * {
				padding: 1em 0;
				flex: 3;
				flex-basis: 0;
				white-space: nowrap;
				text-align: center;
			}
			& > span:first-of-type {
				flex: 1;
				flex-basis: 0;
			}
		}
	}
}

.column-size-2 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	& > div {
		flex: 1;
		& > div {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			& > p {
				padding-left: 1em;
				font-weight: bold;
			}
		}
		& > div:first-of-type > p {
			font-weight: normal;
			padding: 0;
		}
	}
	& p {
		margin: 0;
	}
	& > div:last-of-type > p:nth-child(2n) {
		font-weight: bold;
		margin-bottom: 1em;
	}
}

.person-trips {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #dee2e6;
	padding: 1em 0;

	&:first-of-type {
		padding-top: 0;
	}

	& > div:first-of-type {
		flex: 1;
		align-items: flex-start;
		font-size: 2.5em;
	}
	& > div {
		display: flex;
		flex-direction: column;
		flex: 5;
		& > span:nth-child(2) {
			font-weight: bold;
		}
	}
	i {
		width: 3rem;
	}
}

.master-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1em;
	padding: 0 1.5rem;
}

.travelers-master-body {
	padding: 0 1.5rem;
}

.travelers-filter {
	margin: 1em 0;

	& i:first-of-type {
		margin-top: 0;
	}
	& .compact {
		//background: white;
		margin: 1em 0;
	}
	// & .form-control {
	//   background: white;
	// }
}

.add-program-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	& > div {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 90%;
		& > * {
			flex-basis: 0;
			flex-grow: 1;
			align-items: center;
			margin: 0.25em 0;
		}
		& > p {
			margin-right: 1em;
		}
	}
}

.btn-padded {
	padding: 0 2em;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.list-group.loading .skeleton {
	background: #c0c3c7 !important;
}

.programs.container {
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	.list-group-item {
		&.active,
		&:hover {
			border-radius: 0.6rem;
		}
	}
}

.travellers {
	.list-group-item {
		&.active,
		&:hover {
			border-radius: 0.6rem;
		}
	}
}

@media screen and (min-width: 1200px) {
	.detail-contents {
		& > .container,
		.container-sm,
		.container-md,
		.container-lg,
		.container-xl {
			max-width: 100% !important;
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
	}
}

.detail-contents {
	background: #ffffff !important;
}

.content-container {
	margin-top: 5rem;
}

.circular-progress-outer {
	width: 170px;
	height: 170px;
}

.circular-progress-inner {
	width: 160px;
	height: 160px;
}

.circular-progress-body {
	width: 150px;
	height: 150px;
}

.person-drawer-columns {
	.column-size-2 {
		align-items: flex-start;
	}
}

.travel {
	.download-templates {
		button {
			background-color: #ffffff;
			border-color: #ffffff;
			box-shadow: none;
			color: #4b97f2;
			margin-top: 0.5rem;

			&:hover,
			&:active,
			&:focus {
				background-color: #ffffff !important;
				border-color: #ffffff !important;
				box-shadow: none !important;
				color: #3368a7 !important;
			}
		}
		.show > .btn-primary.dropdown-toggle {
			background-color: #ffffff !important;
			border-color: #ffffff !important;
			box-shadow: none !important;
			color: #3368a7 !important;
		}
	}
}

.list-actions {
	z-index: 1 !important;
}

.high-risk-list {
	.list-group-item {
		cursor: initial;
		pointer-events: none;
		&:hover {
			background-color: transparent;
		}
		&:focus {
			background-color: transparent;
		}
	}
}

.traveller-list,
.programs-list {
	&.list-group {
		.list-group-item {
			border-top: 1px solid rgba(0, 0, 0, 0.125) !important;

			&:hover {
				cursor: pointer !important;
				background-color: #d1d9e2 !important;
				transition: all 0.3s ease-in-out;
			}
		}
	}
}

.list-group-item {
	border-top-width: 0 !important;
}

.program-name {
	button {
		border-radius: 0.6rem !important;
		padding: 1rem !important;

		&:hover {
			i {
				color: #4b97f2;
			}
		}

		i {
			font-size: 1.2rem;
			width: 1.2rem;
			color: #4b97f2;
			margin: 0 !important;
		}
	}

	h1 {
		margin-bottom: 0 !important;
	}
}

.add-individual {
	button {
		width: 162.05px;
	}
}
.profile-pic {
	width: 3rem;
	height: 3rem;
	border-radius: 2rem;
	background-color: #eee;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.profile-pic-large {
	width: 5rem;
	height: 5rem;
	display: inline-block;
	border-radius: 3rem;
	background: #eee;
	overflow: hidden;

	img {
		width: 5rem;
		height: 5rem;
		object-fit: cover;
	}
}

.profile-pic-large i {
	margin: 2rem;
}

.subtitle {
	font-size: 0.7rem;
	a {
		color: black !important;
	}
}
.clickable {
	color: #3368a7;
	cursor: pointer;
	&:hover {
		text-decoration: underline !important;
		color: #3368a7 !important;
	}
}
.high-risk {
	color: #dc3545 !important;
}

.high-risk-list .badge-secondary {
	color: #000 !important;
	background-color: #eee !important;
	width: fit-content !important;
}

.segmented-control {
	display: flex;
	flex-direction: row;
	background-color: #d7dce1;
	padding: 0.15rem 0.2rem;
	border-radius: 0.5rem;
	width: 90%;
	max-width: 280px;

	.segment {
		display: inline-block;
		width: 49%;
		line-height: 28px;
		text-align: center;
		background-color: #f1f1f1;
		font-size: 0.9rem;
		letter-spacing: 0px;
		text-transform: capitalize;
		margin: 0 1px;
		cursor: pointer;
		color: #4b97f2;
		background-color: #d7dce1;
		font-weight: 200;

		&.selected {
			background-color: #4b97f2;
			color: #ffffff;
			border-radius: 0.5rem;
		}

		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.programs.container {
	.badge-container .badge {
		padding: 10px;
		border-radius: 0.4rem;
	}
}

.sub-title {
	border-radius: 0.4rem;
	border: 1px solid rgba(0, 0, 0, 0.7);
	margin-bottom: 0;
	margin-top: 0.3rem;

	.left {
		padding-right: 0.4rem;
		padding-left: 0.4rem;
		display: inline-block;
	}

	.right {
		border-bottom-right-radius: 0.4rem;
		border-top-right-radius: 0.4rem;
		background-color: black;
		color: #fff;
		padding-right: 0.4rem;
		padding-left: 0.4rem;
		display: inline-block;
	}
}

.no-select {
	&:hover {
		cursor: default !important;
		color: #000 !important;
	}

	background-color: transparent !important;
}

.trip-details-container {
	border: 0 !important;
	padding-bottom: 0;
	.trip-details-icon {
		background-color: #007bc2;
		width: 2.5rem;
		height: 2.5rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0.7rem;

		i {
			padding: 0;
			color: #ffffff;
		}
	}

	.trip-details-content {
		//border-bottom: 1px solid rgba(0, 0, 0, 0.125);
		padding-bottom: 1rem;
		padding-left: 0.7rem;
	}
}

.trip-details-content {
	padding-left: 0.7rem;
}

.high-risk {
	color: #dc3545 !important;

	.list-group-item {
		color: #dc3545 !important;
	}

	.trip-details-icon {
		background-color: #dc3545;
	}
}

#rejectReason .modal-footer {
	display: none !important;
}

.high-risk-countries {
	.list-item {
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	}
	.list-group-item {
		margin-right: 6rem;
		border-bottom: 0;
	}
	.list-actions {
		display: flex;
		justify-content: flex-end;
		padding-top: 0.4rem;

		.btn {
			position: relative !important;
		}
	}
}

.high-rist-travel-item {
	cursor: pointer;
	.traveller-icon {
		color: rgba(0, 0, 0, 0.25);
	}
	.btn-padded .btn {
		margin: 0;

		&:hover {
			background-color: rgba(32, 68, 125, 0.1);
		}
	}
}

.high-risk-trip-form {
	.container {
		max-width: initial;
	}
}

.programs-list {
	.high-rist-travel-item {
		.list-group-item:hover {
			cursor: default !important;
			background-color: transparent !important;
		}
	}
}

.full-width-toggle {
	&.border-bottom {
		margin-bottom: 1rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

		& > .form-group {
			margin-bottom: 0;
		}
	}

	.col-sm-4 {
		max-width: 90%;
		flex: 0 0 90%;
		text-align: left !important;
	}
	.col-sm-8 {
		flex: 0 0 10%;
		max-width: 10%;
		align-items: center;
		justify-content: flex-end;
		display: flex;
	}
	.switch {
		justify-content: flex-end;
	}
}

.Toastify__toast-theme--colored.Toastify__toast--default {
	background: #fff !important;
	color: #5b5d61 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
	background: #fff !important;
	color: #5b5d61 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
	background: #029600 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
	background: #ff8a00 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	background: #e3124b !important;
}
.Toastify__toast-body {
	border-radius: 0.6rem !important;
}

.high-risk-trip-buttons {
	// .btn {
	// 	padding: 0
	// }
	i {
		width: 16px;
		height: 16px;
		font-size: 16px;
	}
}

button:focus {
	outline: none !important;
}

.MuiTypography-root {
	font-family: 'OpenSans' !important;
}

.MuiAlert-root {
	background-color: #bfbfbf !important;
	border-radius: 0.6rem !important;
	.MuiAlert-message .MuiTypography-root {
		font-size: 14.4px !important;
	}
}

.passportVisa-drawer {
	.close {
		position: absolute;
		top: 38px;
		right: 20px;
	}
	.drawer-header .title {
		display: flex;
		flex-direction: column;
		.btn {
			padding: 0;
			margin-left: -10px;
			color: #000 !important;
			&:focus {
				box-shadow: none !important;
			}
		}
	}
	.confirm-area {
		.thumbnail-area {
			width: 100%;
			.skeleton-loader {
				width: 100%;
			}
		}
		.controls {
			width: 100%;
			.btn-padded {
				justify-content: center;
			}
		}
	}
}

.passportVisa-drawer-top .Backdrop {
	z-index: 30 !important;
}

.icon-large {
	i {
		font-size: 2rem;
	}
}

.linear-loader {
	width: 100%;
	height: 2px;
	background: linear-gradient(90deg, rgba(0, 123, 194, 0.1) 25%, rgba(0, 123, 194, 0.2) 50%, rgba(0, 123, 194, 0.1) 75%);
	background-size: 200% 100%;
	animation: loading 1.5s infinite;
}

.loader-placeholder {
	height: 2px;
}

@keyframes loading {
	0% {
		background-position: 200% 0;
	}
	100% {
		background-position: -200% 0;
	}
}
