.table-responsive {
    .btn-icon-basic {
        padding: 0.75rem;
    }

    .table {
        td {
            vertical-align: middle;
        }
    }
}